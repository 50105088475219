.modal-content{
    background-color: transparent;
    border: none;

    .modal-header{
        border: none;
        padding-left: 0;
        padding-right: 0;
    }

    .modal-body{
        background-color: var(--bs-white);
        border-radius: 8px;
    }

    .modal-footer{
        padding-top: 2rem;
        border: none;
        justify-content: center;
    }
}