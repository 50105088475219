* {
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    letter-spacing: 0.015em;
    word-spacing: 0.001em;
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Ubuntu, Source Sans Pro, sans-serif;
}

h4 {

    &.title-detail {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
    }

    &.title-detail-green {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: var(--bs-text-link);
    }
}

a {
    color: var(--bs-text-link);
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: var(--bs-text-link);
    }

    &.underline {
        text-decoration: underline;
    }
}

.container {
    margin-top: 16px;
    padding-bottom: 30px;
    
    @include media-breakpoint-down(lg) {
        margin-top: 0;
    }
}

button {

    &.text-link-terms {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
    }
}

// utilities
.navbar {

    .logo {
        cursor: pointer;
        height: 40px;
    }

    .btn-sm {
        font-size: 24px;
        padding: 10px 32px;
    }

    @include media-breakpoint-down(xl) {

        &.fixed-top {
            background-color: transparent;
        }

        .logo {
            height: 32px;
        }
    }

    @include media-breakpoint-down(lg) {
        .btn-sm {
            font-size: 18px;
            padding: 10px 13px;
        }
    }
}

.pointer {
    cursor: pointer;
}

.text-muted {
    color: var(--bs-text-info);
}

hr {
    opacity: 0.2;
    background-color: var(--bs-separator-horizontal);
}

.vr {
    opacity: 1;
    background-color: var(--bs-separator-vertical);
}

//Background Right Pages
.bg-right-page {
    position: absolute;
    top: 0;
    left: calc(var(--bs-gutter-x) * 0.5);
    height: 100%;
    width: auto;
    z-index: 0;

    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.page-use-banner {

    .navbar {
        z-index: 10;
    }

    .app-container {
        margin-top: -82px;
    }

    @include media-breakpoint-up(xl) {
        background: linear-gradient(to right, var(--bs-white) 65%, var(--bs-primary) 35%);
    }

    @include media-breakpoint-down(xl) {
        .app-container {
            margin-top: -75px;
        }
    }
}

.app-container {

    .message-col {
        overflow: auto;
        margin-top: 110px;
        height: calc(100% - 140px);

        .content-start {
            max-width: 330px;

            small {
                font-size: 12px;
                line-height: 14px;
            }
        }

        @include media-breakpoint-down(xl) {
            overflow: hidden;
            margin-top: 15px;
            height: auto;
            z-index: 2;

            &.page-success {
                margin-top: -90px;
            }

            .img-card {
                max-height: 140px;
                margin: 0 auto;
            }

            h6 {
                font-size: 16px;
                line-height: 24px;
            }

            .content-start {
                max-width: initial;

                .button-start {
                    position: fixed;
                    bottom: -24px;
                    left: 0;
                    right: 0;
                    background: var(--bs-white);
                    padding: 24px 0;
                    z-index: 1000;
                    width: 100%;
                    height: auto;

                    button {
                        min-width: 300px;
                        filter: drop-shadow(0px 6px 20px rgba(234, 84, 0, 0.2))
                    }
                }
            }
        }
    }

    .banner-col {
        position: relative;

        .img-card {
            max-height: 600px;
            object-fit: initial;
            z-index: 2;
            margin-left: 46px;
        }

        @include media-breakpoint-down(xl) {
            background-color: var(--bs-primary);
            height: 281px;
            // mask-image: url(../../assets/img/mobile-mask-clip.svg);
            mask-repeat: no-repeat;
            mask-size: 100%;
            mask-position: bottom;
            padding-top: 10px;

            h1 {
                font-size: 18px;
                line-height: 24px;
                margin-top: 77px;
            }
        }
    }
}

// texts
.page-title {
    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }

    h4 {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    i {
        font-weight: 300;
        font-size: 12px;
        line-height: 24px;
    }
}

p, ul li {
    font-size: 14px;
    line-height: 24px;
    color: var(--bs-text-default);
}

ul.text-muted {
    
    li {
        color: var(--bs-text-info)
    }
}

small {
    font-size: 10px;
    line-height: 14px;
    display: inherit;
    color: var(--bs-text-default);
}

.invalid {
    margin-top: 8px;
    font-size: 12px;
    line-height: 12px;
    color: var(--bs-border-error);
    text-align: right;
}

//Carrousel
.carousel-fade {
    .carousel-inner {
        .carousel-item {
            transition: opacity 500ms;
            // transform: translate3d(0, 0, 0, 0);
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#title-carousel {
    min-height: 120px;
    display: flex;
    align-items: center;

    &.active {
        animation: fadeIn 1s alternate;
    }

    @include media-breakpoint-down(xl) {
        min-height: initial;
    }
}

.carousel-control-prev-icon {
    // background-image: url("../../assets/icons/icon-seta-esquerda.svg");
}

.carousel-control-next-icon {
    // background-image: url("../../assets/icons/icon-seta-direita.svg");
}

.help-text {

    p, a {
        font-size: 14px;

        &:not(.btn) {
            line-height: 20px;
        }
    }
}

//Footer
.footer {
    max-height: 34px;

    &.bg-footer {
        background-color: var(--bs-bg-footer);
    }

    p {
        margin: 0;
    }

    @include media-breakpoint-down(xl) {

        p {
            background-color: var(--bs-bg-footer);
            font-size: 12px;
            color: var(--bs-label-field) !important;
            margin: 0px -12px;
            padding: 4px 0;
        }
    }
}

.use-banner-footer {
    margin-top: 54px;

    @include media-breakpoint-down(xl) {
        margin-top: 30px;

        &.inicio {
            margin-bottom: 100px;
        }
    }

}

.h-responsive {
    min-height: calc(100vh - 124px);

    @include media-breakpoint-down(xl) {
        min-height: calc(100vh - 112px);
    }
}

.fill-primary {
    fill: var(--bs-primary);
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--bs-white);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--bs-border-price);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--bs-border-price);
}

/* Hide Recaptcha */
.grecaptcha-badge {
    display: none !important;
}