.carousel {
    width: 520px;
    height: 379px;
    background: linear-gradient(270deg, #C7FFA8 0%, #A1FFCE 100%);
    border-radius: 24px;

    .carousel-indicators {
        top: 35px;
        left: 32px;
        justify-content: flex-start;
        margin: 0;

        [data-bs-target] {
            width: 8px;
            height: 8px;
            opacity: 1;
            border-radius: 8px;
            margin-right: 4px;
            margin-left: 4px;
            background-color: var(--bs-white);
            border: none;
            transition: all 0.25s ease-in-out;
            
            &.active {
                width: 24px;
                background-color: var(--bs-green);
            }
        }
    }

    .carousel-inner {
        display: flex;
        align-items: center;
        align-content: center;
        height: 100%;

        .carousel-item {
            p {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 400;
                font-size: 19px;
                line-height: 28px;
            }
            img {
                position: absolute;
                width: auto;
                height: 379px;
                right: 0;
                z-index: -1;
            }
        }

    }

}