.table-discounts {

    .line-details {

        .info {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: var(--bs-text-info);
        }
        .value {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--bs-text-default);
        }
    }

    .line-total {
        @extend .line-details;
        .info, .value {
            color: var(--bs-text-link);
            font-size: 16px;
            line-height: 24px;
        }
    }

    tbody {
        border-top: 1px solid var(--bs-light);
        border-bottom: 1px solid var(--bs-light);

        .line-details {

            td {
                padding: 8px 0;
            }

            &:first-of-type {

                td {
                    padding-top: 16px;
                }
            }

            &:last-of-type {

                td {
                    padding-bottom: 16px;
                }
            }
        }
    }

    tfoot {

        .line-details {

            td {
                padding-top: 16px;
            }
        }
    }
}

.table-resume {
    @extend .table-discounts;

    .line-details {

        .info {
            color: var(--bs-text-default);
        }
        .value {
        }
    }

    .line-total {
        @extend .line-details;
        .info, .value {
        }
    }

    tbody {
        border-top: 1px solid #E6E3EA;
        border-bottom: 1px solid #E6E3EA;

        .line-details {

            td {
            }

            &:first-of-type {

                td {
                }
            }

            &:last-of-type {

                td {
                }
            }
        }
    }

    tfoot {

        .line-details {

            td {
            }
        }
    }
}