.tooltip-inner {
    background-color: #00604D;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    padding: 5px 10px 10px 10px;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-top-color: #00604D;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    border-bottom-color: #00604D;
}
.bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    border-left-color: #00604D;
}
.bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    border-right-color: #00604D;
}