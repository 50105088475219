.voucher {

    legend {
        font-size: 12px;
        font-weight: normal;
        line-height: 24px;
        color: var(--bs-label-field);
        margin-bottom: -12px;
        margin-left: 12px;
        background-color: var(--bs-white);
        width: auto;
        padding-left: 4px;
        padding-right: 4px;
    }

    .voucher-border {
        border: 1px solid var(--bs-primary);
        border-radius: 14px;
        padding-right: 15px;
    }

    .voucher-detail {
        margin-top: 12px;
    }

    .input-group {

        img {
            width: 40px;
        }
        
        .form-control {
            font-weight: bold;
            margin-left: 0;
            border-top-left-radius: "10px";
            border-bottom-left-radius: "10px";
        }
    }

}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-radius: 10px;
}