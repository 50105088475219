.btn {
    padding: 9px 24px;
    min-height: 48px;
    border-radius: 62px;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
}

.btn-sm, .btn-group-sm > .btn {
    padding: 9px 30px;
    min-height: 36px;
    border-radius: 40px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    box-shadow: 0px 4px 15px rgba(218, 101, 41, 0.25);
    border-radius: 34px;
}

.btn-primary {
    background-color: var(--bs-bg-0800);
    border-color: var(--bs-bg-0800);
    color: var(--bs-text-0800);

    &:hover,
    &:active,
    &:focus {
        background-color: var(--bs-hover-0800);
        border-color: var(--bs-hover-0800);
        box-shadow: none !important;
        color: var(--bs-text-0800);
    }

    @include media-breakpoint-down(xl) {
        padding: 11px 13px;
        font-size: 18px;
        line-height: 18px;
    }
}

.btn-secondary {
    background-color: var(--bs-bg-button);
    border-color: var(--bs-bg-button);
    color: var(--bs-text-button);

    &:hover,
    &:active,
    &:focus {
        background-color: var(--bs-hover-button);
        border-color: var(--bs-hover-button);
        box-shadow: none !important;
        color: var(--bs-text-button);
    }

    &:disabled, &.disabled {
        color: var(--bs-text-button);
    }
}

.btn-outline-text-info {
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
        background-color: initial;
        color: var(--bs-text-info);
    }
}