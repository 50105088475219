.card {
    border-radius: 15px;

    .card-header:first-child {
        border-radius: 15px 15px 0 0;
    }

    .card-footer:last-child {
        border-radius: 0 0 15px 15px;
    }
}

.discount-card {
    width: 360px;

    .card-party {
        position: absolute;
        top: -215px;
        left: calc(50% - 124px);
        opacity: 0;
        animation-name: show-animated;
        animation-duration: 0.6s;
        transform: scaleY(1);
        transform-origin: bottom;
        z-index: 999;
    }

    
    @keyframes show-animated {
        from {
            transform: scaleY(0);
            top: -190px;
            opacity: 0;
            z-index: 999;
        }
        to {
            transform: scaleY(1);
            top: -215px;
            opacity: 1;
            z-index: -1;
        }
    }

    @keyframes show-animated-mobile {
        from {
            transform: scaleY(0);
            bottom: 0;
            opacity: 1;
            z-index: 999;
        }
        to {
            transform: scaleY(1);
            bottom: 0;
            opacity: 0;
            z-index: -1;
        }
    }

    .card-header, .card-footer {
        padding: 0;

        .row {
            padding: 19px 12px;
        }

        .float-icon {
            position: absolute;
            left: 12px;
        }

        .detail, .value {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        .value {
            font-weight: 700;
        }
    }

    .card-header {

        &.bg-text-link {
            border-color: var(--bs-text-link);
        }
        &.bg-secondary {
            border-color: var(--bs-secondary);
        }
    }

    .card-footer {
        border-color: #E6E3EA;
        
        .separator {
            margin: 0;
            background-color: #E6E3EA;
        }

        .detail {
            border-color: #E6E3EA;
        }

    }

    @include media-breakpoint-down(lg) {
        width: 100%;
        border-radius: 0 !important;
        position: initial;

        .card-party {
            top: initial;
            bottom: 0;
            animation-name: show-animated-mobile;
            animation-duration: 1.6s;
        }

        .card-header, .card-footer {
            border-radius: 0 !important;
    
            .row {
                padding: 10px 12px;
                margin: 0;
            }

            .detail, .value {
                font-size: 12px;
                line-height: 14px;
            }
        }

        .card-footer {

            .separator {
                width: 1px;
                height: auto;
            }
        }
    }
}

.border-radius {
    border-radius: 10px;
}

.card-border-start-radius {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.card-border-end-radius {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.card-resume {

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }

    .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
    }
}

.cards-benefits {

    .row:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .row:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

@include media-breakpoint-down(lg) {

    .card-border-start-radius {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .card-border-end-radius {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
  
    .cards-benefits {

        .row:first-of-type {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        .row:last-of-type {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}