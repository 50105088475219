.show > .btn-primary.dropdown-toggle {
    color: var(--bs-text-info);
    background-color: var(--bs-bg-field);
    border: 1px solid var(--bs-border-active);
}

.dropdown {
    .dropdown-toggle {
        min-height: 51px;
        padding-left: 24px;
        top: calc(50% - 51px/2);
        border-radius: 10px;
        border-color: var(--bs-border-field);
        font-size: 12px;
        line-height: 24px;
        width: 100%;
        font-weight: normal;
        text-align: start;
        font-style: italic;

        &::after {
            border-top: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-left: 0.4em solid transparent;
            float: right;
            margin-left: 0;
            vertical-align: 0;
            margin-top: 0.8em;
        }

        &.is-valid {
            border-color: var(--bs-border-success);
            font-style: normal;
        }

        &.is-invalid {
            border-color: var(--bs-border-error);
        }
    }

    .btn-primary {
        background: var(--bs-bg-field);
        color: var(--bs-text-info);
    }

    .dropdown-menu {
        background-color: var(--bs-bg-field);
        font-size: 14px;
        line-height: 24px;
        width: 100%;

        .dropdown-item {
            padding: 0.25rem 1.5rem;

            &:active {
                background-color: var(--bs-primary);
            }
        }
    }
}