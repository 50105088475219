// Inputs
.form-control {
    min-height: 51px;
    padding-left: 24px;
    top: calc(50% - 51px/2);
    background: var(--bs-bg-field);
    border-radius: 10px;
    border-color: var(--bs-border-field);
    font-size: 12px;
    line-height: 24px;

    &-focus {
        background: var(--bs-bg-field);
        border: 1px solid var(--bs-border-active);
        box-shadow: none;
    }

    &:focus {
        background: var(--bs-bg-field);
        border: 1px solid var(--bs-border-active);
        box-shadow: none;
    }

    &:disabled {
        background-color: var(--bs-gray-200);
        border-color: var(--bs-border-field);
        cursor: not-allowed;
    }

    &::placeholder {
        min-height: 24px;
        top: calc(50% - 24px/2 + 0.5px);
        font-style: italic;
        font-size: 12px;
        line-height: 24px;
        color: var(--bs-text-info);
    }

    &.is-invalid {
        border-color: var(--bs-border-error);
        background-image: none;

        &:focus {
            background: var(--bs-bg-field);
            box-shadow: none;
        }
    }

    &.is-valid {
        border-color: var(--bs-border-success);
        background-image: none;

        &:focus {
            background: var(--bs-bg-field);
            box-shadow: none;
        }
    }
}

.form-group {
    position: relative;

    .invalid-feedback {
        position: absolute;
        bottom: -20px;
        right: 0;
        margin-top: 8px;
        font-size: 12px;
        line-height: 12px;
        color: var(--bs-border-error);
        text-align: right;
    }
    
    .form-text {
        position: absolute;
        bottom: -20px;
        left: 0;
        margin-top: 8px;
        font-size: 12px;
        line-height: 12px;
        color: var(--bs-success);
        text-align: left;
    }
}

.form-label,
label {
    padding-left: 12px;
    font-size: 12px;
    font-weight: 500;
    color: var(--bs-label-field);
    margin-bottom: 10px;
}

.form-check {
    margin-bottom: 0.325rem;

    .form-check-label {
        font-weight: 400;
        padding-left: 0;
        margin-bottom: 0;

        b,
        strong {
            font-weight: 600;
        }
    }
}


.form-text {
    font-size: 12px;
    line-height: 24px;
    color: var(--bs-label-field);
}

.was-validated .form-control:valid {
    background-image: none;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}

.was-validated .form-control:invalid {
    background-image: none;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}

.input-group-text {
    background-color: transparent;
    border: none;
}

.form-check-label {
    font-size: 12px;
    line-height: 20px;
    color: var(--bs-label-field);
}

.form-check-input {
    border-color: var(--bs-primary);
    border-width: 2px;
    margin-top: 6px;

    &:focus {
        border-color: var(--bs-primary);
        box-shadow: none;
    }

    &.is-invalid {
        &:checked {
            background-color: transparent;
        }
    }

    &:checked {
        border-color: var(--bs-primary);
        background-color: transparent;
        &[type=checkbox] {
            position: relative;
            display: inline-block;
            width: 16px;
            height: 16px;
            //background-image: url('../../assets/icons/icon-check.svg');
            background-image: none;
            &::before {
                position: absolute;
                left: -5px;
                top: 15%;
                height: 60%;
                width: 2px;
                background-color: var(--bs-primary);
                content: "";
                transform: translateX(10px) rotate(-45deg);
                transform-origin: left bottom;
            }

            &::after {
                position: absolute;
                left: -5px;
                bottom: 3px;
                height: 2px;
                width: 125%;
                background-color: var(--bs-primary);
                content: "";
                transform: translateX(10px) rotate(-45deg);
                transform-origin: left bottom;
            }
        }
    }
}

//Accordion
.accordion {
    .accordion-item {
        border: none;

        .accordion-button {
            color: var(--bs-icons);
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            padding: 16px 32px;

            &:not(:first-of-type) {
                border-top: 1px solid var(--bs-separator-horizontal);
            }

            &::after {
                // background-image: url('../../assets/icons/icon-arrow-down.svg');
                background-size: 16px;
                transform: rotate(0deg);
            }

            &.collapsed {
                border-top: 1px solid var(--bs-separator-horizontal);
            }

            &:not(.collapsed) {
                color: var(--bs-text-default);
                background-color: var(--bs-white);
                box-shadow: none;

                &::after {
                    // background-image: url('../../assets/icons/icon-arrow-down.svg');
                    background-size: 16px;
                    transform: rotate(-180deg);
                    margin-right: 5px;
                }
            }

            &:focus {
                border: none;
                box-shadow: none;
            }

            &.validated {
                &.collapsed {
                    border-top: none;
                }

                &::after {
                    // background-image: url('../../assets/icons/icon-ok.svg');
                    background-size: 20px;
                    transform: rotate(0);
                }
            }
        }

        .accordion-body {
            padding: 1rem 2rem;
        }
    }

}

.pagamento-form {

    .col.disabled {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--bs-white);
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

.spinner-class{
    top: 43px;
    right: 8px;
}

.button-copytoclipboard{
    top: 43px;
    right: 8px;
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    background: #E8E3DA;
    border-radius: 4px;
    min-height: 32px;
    border: none;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background: #E8E3DA;
        border: none;
        box-shadow: none;
    }

    &.copy-successfully {
        background: var(--bs-neon);
    }

    &:before {
        content: "";
        background: var(--bs-yellow);
        width: 10px;
        height: 32px;
        position: absolute;
        left: -10px;
    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: var(--bs-label-field);
    }
}

.form-check-input.is-invalid ~ .form-check-label {
    color: var(--bs-label-field);
}