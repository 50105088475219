@media print {

    @page { 
        margin: 0.5cm;
    }

    *,
    .text-white {
        color: black !important;
    }

    body {
        height: auto !important;
        overflow: auto !important;
    }

    .no-print {
        display: none;
    }

    .print-space-reset {
        margin: 0 !important;
        padding: 0 !important;
    }

    .print-spacer {
        margin-top: 30px;
        padding-bottom: 30px;
        border-bottom: 2px solid #E6E3EA;
    }

    .border-end {
        border: none !important;
    }

    .adopt-controller-button {
        display: none;
    }
}