.cb-partners-category {

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 32px;
    }
}

.cb-partners-item {

    .logo-box {
        width: 194px;
        height: 62px;
        border: 1px solid #E6E3EA;
    }

    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }
}

@include media-breakpoint-down(lg) {
    
    .cb-partners-item {
    
        .logo-box {
            width: auto;
            height: auto;
            border: none;
        }
    }
}